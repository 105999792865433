.member-status {
  &__notsent {
    background-color: $brand-color;
    color: white;
  }
  &__accepted {
    background-color: $green;
    color: white;
  }
  &__sent {
    background-color: $blue;
    color: white;
  }
  &__open {
    background-color: $darkblue;
    color: white;
  }
  &__resent {
    background-color: $blue;
    color: white;
  }
  &__declined {
    background-color: var(--background);
    color: #999999;
  }
  &__bounce {
    background-color: $red;
    color: white;
  }
  &__undelivered {
    background-color: $red;
    color: #999999;
  }
}

.schedule-status {
  &__notsent {
    background-color: $brand-color;
    color: white;
  }

  &__sent {
    background-color: $blue;
    color: white;
  }
  &__open {
    background-color: $green;
    color: white;
  }
  &__click {
    background-color: $green;
    color: white;
  }
  &__resent {
    background-color: $green;
    color: white;
  }
  &__bounce {
    background-color: $red;
    color: white;
  }
  &__undelivered {
    background-color: $red;
    color: #999999;
  }
}


// =================================================================================================

.event-status {
  &__draft {
    background-color: $brand-color;
    color: white;
  }
  &__live {
    background-color: $green;
    color: white;
  }
  &__open {
    background-color: $blue;
    color: white;
  }
  &__completed {
    background-color: var(--background);
    color: #999999;
  }
}

// =================================================================================================

.guest-status {
  &__notyet {
    background-color: #999999;
    color: white;
  }
  &__accepted {
    background-color: $green;
    color: white;
  }
  &__sent {
    background-color: $blue;
    color: white;
  }
  &__open {
    background-color: $darkblue;
    color: white;
  }
  &__resent {
    background-color: $blue;
    color: white;
  }
  &__declined {
    background-color: var(--background);
    color: #999999;
  }
  &__bounce {
    background-color: $red;
    color: white;
  }
  &__undelivered {
    background-color: $red;
    color: #999999;
  }
  &__waitlist {
    background-color: $title-color;
    color: white;
  }
  &__rejected {
    background-color: black;
    color: white;
  }
  // ----------------------------------------
  &__notattended {
    background-color: black;
    color: white;
  }
  &__arrived {
    background-color: $darkgreen;
    color: white;
  }
  &__notyetarrived {
    background-color: var(--background);
    color: #999999;
  }
}
