.invite-guests,.invite-schedule {
  .modal-dialog {
    max-width: 100%;
    width: 85vw;
  }

  .modal-body {
    display: flex;
    height: 77vh;
  }

  iframe {
    flex-grow: 1;
    margin: 0 1rem;
    border: solid 1px $input-border-color;
  }

  &__form {
    width: 340px;
    overflow: auto;
  }
}
button#invite-guests__image {
  height: 100%;
}

/* lg - iPad landscape and smaller */
@media only screen and (max-width: 1024px) {
  .invite-guests,.invite-schedule {
    iframe {
      display: none;
    }
    &__form {
      width: 100%;
    }
    .modal-body {
      height: auto;
    }
  }
}
