.swal2-styled.btn.btn,
.btn.btn {
  // height: 40px;
  // border: none;
  // text-transform: uppercase;
  // letter-spacing: 1px;
  // padding: 0 2rem;
  outline: none;
  // box-shadow: none !important;
  // line-height: 40px;
  // border-radius: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Carl's styling */
  position: relative;
  margin: 0;
  border: 1px solid $button-border-color;
  background: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  min-height: $button-height;
  line-height: calc(#{$button-height} - 2px);
  // font-family: 'omega-bold', arial, helvetica, sans-serif;
  display: inline-block;
  zoom: 1;
  box-sizing: border-box;
  padding: 0 2rem 0 1.7rem;
  text-align: center;
  overflow: hidden;
  transition: background 0.2s ease, color 0.2s ease, border 0.2s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  box-shadow: none !important;
  font-weight: bold;

  color: inherit;

  // -----------------------------------------------------------------------------------------------
  // Border for when button has focus/tab
  &:focus {
    border-color: rgba(0, 0, 0, 0.8);
  }

  // -----------------------------------------------------------------------------------------------
  // Coloured rectangle to slide in on hover

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $title-color;
    transform: translate3d(0, 105%, 0);
    transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  }

  // -----------------------------------------------------------------------------------------------
  // Hover/selected styling

  &:not(:disabled):hover,
  &:not(:disabled).btn--selected {
    color: #fff;
  }

  &:not(:disabled):hover:before,
  &:not(:disabled).btn--selected:before {
    transform: translate3d(0, 0, 0);
  }

  // -----------------------------------------------------------------------------------------------
  // Primary button
  &.btn-primary {
    background: $title-color;
    color: #fff;
    &:before {
      background: #fff;
    }
    &:not(:disabled):hover,
    &:not(:disabled).btn--selected {
      color: $title-color;
    }

    border-color: $title-color;
    &:focus {
      border-color: rgba(0, 0, 0, 0.8);
    }
    &:focus:hover,
    &:focus.btn--selected {
      border-color: $title-color;
    }
  }

  // -----------------------------------------------------------------------------------------------
  // Small button
  &.btn-small {
    line-height: 30px;
    min-height: 30px;
    padding-left: 1rem;
    padding-right: 0.85rem;
  }

  &.disabled,
  &:disabled {
    background-color: #e1e1e1;
    border-color: #e1e1e1;
  }

  label,
  span,
  i {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    padding: 1px 0 3px;
    font-size: 16px;
    vertical-align: middle;
    line-height: 112%;
  }

  i.fas {
    margin-right: 1rem;
  }
}

// =================================================================================================
// Modal close button
button.close {
  padding: 1rem;
  outline: none;
}

// =================================================================================================
/* Hide underlines if we have a button inside/working as a link/anchor */
a button {
  text-decoration: none;
}

// =================================================================================================
/* md - iPad portrait and smaller */
@media only screen and (max-width: 768px) {
  .swal2-styled.btn.btn,
  .btn.btn {
    padding: 0 1.5rem; // Less padding
  }
}

.btn.btn.email__download-html,
.email__download-html {
  margin-left: auto;
}