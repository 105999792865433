.email-member {
  .modal-dialog {
    max-width: 100%;
    width: 85vw;
  }

  .modal-body {
    display: flex;
    height: 77vh;
  }

  iframe {
    flex-grow: 1;
    margin: 0 1rem;
    border: solid 1px $input-border-color;
  }

  &__form {
    width: 340px;
    overflow: auto;
  }
}
button#email-member__image {
  height: 100%;
}

.image-browser__placeholder {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.email-member__handlerbar-trigger,
.btn.email-member__handlerbar-trigger {
  margin-bottom: 2px;
}

.email-member__members ul {
  padding-bottom: 4px;
  padding-left: 20px;
  margin-bottom: 0;
}

/* lg - iPad landscape and smaller */
@media only screen and (max-width: 1024px) {
  .email-member {
    iframe {
      display: none;
    }
    &__form {
      width: 100%;
    }
    .modal-body {
      height: auto;
    }
  }
}
