.event-list {
  &__top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn {
      margin-right: 1rem;
    }
    .separator {
      flex-grow: 1;
    }
  }
}

#event-list_filter {
  width: 300px;
  label {
    margin: 0;
  }
}
