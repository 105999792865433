.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 9999;

  opacity: 0.7;

  background-color: white;
  background-image: url('../img/loader.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 4rem;

  cursor: wait;
}
