.header-bar {
  height: 5rem;
  background-color: $header-bar-background;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 4rem;

  &__spacer {
    flex-grow: 1;
  }
  &__separator {
    height: 3rem;
    border-left: solid 1px rgba(128, 128, 128, 0.5);
    margin: 0 1rem;
  }

  &__tab {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 2rem;
    border-top: solid 3px transparent;

    color: $title-color;

    font-family: $title-font;
    font-weight: 600;
    font-size: 1.35rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    * {
      text-decoration: none;
    }
    &:hover {
      color: $title-color;
      text-decoration: none;
    }

    .fas {
      margin-right: 2rem;
    }

    cursor: pointer;
    * {
      cursor: pointer;
    }

    border-bottom: solid 3px transparent;
    transition: border-color 300ms;
    &:hover {
      border-bottom-color: fade-out($title-color, 0.8);
    }
    &--selected {
      border-bottom-color: $title-color !important;
    }
  }

  &--centered {
    justify-content: center;
  }

  img {
    object-fit: contain;
    height: 60%;
  }

  .btn.btn {
    border: none !important;
  }
  #header-bar__configuration {
    color: inherit;
    .btn {
      padding: 0 1.2rem;
      i {
        margin: 0;
      }
    }
  }
}

/* md - iPad portrait and smaller */
@media only screen and (max-width: 768px) {
  .header-bar__tab {
    font-size: 0;
    letter-spacing: 0;
    i.fas {
      font-size: 1.35rem;
      margin-right: 0;
    }
  }
}
