body.dark {
  --background: #020406;
  --section-background-1: #1e222a;
  --section-background-2: #0e141a;
  --even-row-background: #292e34;
  --odd-row-background: #30343c;
  --column-line-color: #1b2129;
  --input-border-color: #2f333b;
  --placeholder-color: #2f333b;

  --button-height: 42px;

  --brand-color: #cb1e54;
  --red: #c70101;
  --white: #ffffff;
  --green: #06b7a5;
  --darkgreen: #047c70;
  --blue: #7087bd;
  --darkblue: #4f6aab;

  --header-bar-background: var(--section-background-1);
  --title-color: var(--brand-color);
  --input-background: var(--section-background-2);

  --base-color: #dfe0de;
}
