.side-panel {
  display: none;
  flex-direction: column;

  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  max-width: 100%;
  background-color: #ffffff;

  box-shadow: -10px 0 24px rgba(0, 0, 0, 0.04), -6px 0 6px rgba(0, 0, 0, 0.08);

  max-height: 100%;
  overflow: auto;

  transition: transform 200ms, opacity 200ms;
  opacity: 1;
  transform: translateX(0px);
  z-index: 1;

  width: 800px;

  &.hidden {
    opacity: 0;
    transform: translateX(100px);
  }

  &__header {
    background-color: var(--section-background-1);
    h2 {
      margin: 0;
      flex-grow: 1;
    }
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0.4rem 2rem 0 2rem;
  }

  .data-row {
    margin-left: 0px;
    margin-right: 0px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: auto;

    &-left {
      padding: 0.2rem 0rem 0.2rem 1rem;
      width: 1px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    &-right {
      display: flex;
      flex-direction: column;
      padding: 0.7rem 1rem;
      flex-shrink: 0;
      .btn {
        margin-bottom: 0.7rem;
      }
    }

    h3 {
      margin-left: 1rem;
    }
  }
}
