.day-selector {
  .form-inline:not(:last-child) {
    margin-bottom: 0;
  }

  .form-inline:not(:first-child) {
    padding-top: 0;
  }

  .day-selector__mm {
    transform: translateX(-1px);
    flex-grow: 1;
    max-width: none;
  }

  .day-selector__yyyy {
    transform: translateX(-2px);
  }
}
