.search-field {
  height: $button-height;
  position: relative;
  flex-grow: 1;
  margin-right: 1rem;

  input {
    width: 100%;
    height: calc(#{$button-height} + 2px);
    padding-left: calc(#{$button-height} + 1rem);
  }
  &:before {
    content: '\f002';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    background-color: white;
    border-right: solid 1px #e6e6e4;
    width: $button-height;
    height: calc(#{$button-height} - 2px);
    display: inline-block;
    margin: 0;
    line-height: 36px;
    font-size: 1rem;
    color: #cb1e54;
    position: absolute;
    left: 2px;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
