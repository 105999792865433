/* Light mode */
$background: #e1e1e1;
$section-background-1: #f1f3f1;
$section-background-2: #ffffff;
$even-row-background: #f7fafa;
$odd-row-background: #ffffff;
$column-line-color: #e5e7e5;
$input-border-color: #e6e6e4;
$placeholder-color: #818485;

$button-border-color: #e2e2e2;

$button-height: 42px;

$brand-color: #cb1e54;
$red: #c70101;
$white: #ffffff;
$green: #06b7a5;
$darkgreen: #047c70;
$blue: #7087bd;
$darkblue: #4f6aab;

$header-bar-background: $white;
$title-color: $brand-color;
$input-background: $white;

$title-font: 'OMEGA CT Regular','OMEGA CT',sans-serif;
$base-font: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
$base-color: #333333;

:root {
  --background: #e1e1e1;
  --section-background-1: #f1f3f1;
  --section-background-2: #ffffff;
  --even-row-background: #f7fafa;
  --odd-row-background: #ffffff;
  --column-line-color: #e5e7e5;
  --input-border-color: #e6e6e4;
  --placeholder-color: #818485;

  --button-height: 42px;

  --brand-color: #cb1e54;
  --red: #c70101;
  --white: #ffffff;
  --green: #06b7a5;
  --darkgreen: #047c70;
  --blue: #7087bd;
  --darkblue: #4f6aab;
  --base-color: #333333;
}

html,
body {
  background: var(--background);
  font-family: $base-font;
  text-rendering: optimizeLegibility;
  color: #333333;
  font-size: 14px;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  padding-right: 0 !important; // Counteract padding added to body by sweetalert2
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: 1.2rem;
}

// h2 {
//   font-size: 2rem;
//   margin-bottom: 1rem;
// }

h1,
h2,
h3,
h4,
h5,
th,
button {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $title-font;
}

h1 {
  color: $title-color;
}

h3 {
  margin-top: 2rem;
}

section {
  background-color: var(--section-background-1);
  padding: 1rem 2rem;
}

body > section {
  margin: 1rem 2rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    color: $title-color;
    font-weight: 600;
    font-size: 1.35rem;
    margin: 0;
  }
}

input[type='text']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='file']:focus,
textarea:focus {
  outline: $title-color solid 2px;
}

.close {
  transform: translateY(-5px);
  font-size: 2rem;
  font-weight: 400;
}

h2.alert__title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $title-font;
  color: $title-color;
  font-size: 1.4rem;
  margin-bottom: 1.1rem;
}

@media (min-width: 768px) {
  .navbar-nav > li {
    float: left;
  }
}

.navbar {
  min-height: 70px;
}

.navbar-nav > li > a {
  font-weight: 600;
  letter-spacing: 1.2px;
}

i.i18n {
  color: transparent;
  letter-spacing: -99px;
}

/* lg - iPad landscape and smaller */
@media only screen and (max-width: 1024px) {
  body > section {
    margin: 0.8rem 0.8rem 0rem 0.8rem;
  }

  section {
    padding: 0.8rem 0.8rem;
  }
}

/* md - iPad portrait and smaller */
@media only screen and (max-width: 768px) {
  body > section {
    margin: 0.8rem 0rem 0rem 0rem;
  }

  section {
    padding: 0.8rem 1.1rem;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.row-gap {
  row-gap: 1rem;
}

.pad-span{
  margin-left: 0.5rem;
}

//pill active 
.pill-active {
  background-color: $brand-color;
  color: white;
}

// the whole codebase has terrible cascading class issues requiring these additional styles
.btn.btn.hidden,
.hidden {
  display: none;
}