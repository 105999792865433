.member-details {
  display: none;

  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 900px;
  max-width: 100%;
  background-color: #ffffff;

  //   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  //   box-shadow: -19px 0 38px rgba(0, 0, 0, 0.3), -15px 0 12px rgba(0, 0, 0, 0.22);
  box-shadow: -10px 0 24px rgba(0, 0, 0, 0.04), -6px 0 6px rgba(0, 0, 0, 0.08);

  max-height: 100%;
  overflow: auto;

  &__header {
    background-color: var(--section-background-1);
    h2 {
      margin: 0;
      flex-grow: 1;
    }
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0.4rem 2rem 0 2rem;
  }

  .data-row {
    margin-left: 0px;
    margin-right: 0px;
  }

  &__content {
    display: flex;
    flex-direction: row;

    &-left {
      padding: 0.2rem 0rem 0.2rem 1rem;
      flex-grow: 1;
    }
    &-right {
      display: flex;
      flex-direction: column;
      padding: 0.7rem 1rem;
      flex-shrink: 0;
      .btn {
        margin-bottom: 0.7rem;
      }
    }
  }
  transition: transform 200ms, opacity 200ms;
  opacity: 1;
  transform: translateX(0px);
  h3 {
    margin-left: 1rem;
  }
}

.member-details.hidden {
  opacity: 0;
  transform: translateX(100px);
}

.member-details .side-panel__header h2 {
  position: relative;
  padding-left: 42px;
}

.vip-badge {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.vip-badge svg {
  display: block;
  width: 31px;
  height: 28px;
}