.language-selector__option {
  cursor: pointer;
  padding-right: .5rem;
  border-bottom: solid 3px transparent;
  transition: border-color 300ms;

  &:hover {
    border-bottom-color: fade-out($title-color, 0.8);
  }

  &--selected {
    border-bottom-color: $title-color !important;
  }
}
  
.language-selector__option.disabled {
  opacity: 0.4;
}

.hidden {
  display: none;
}