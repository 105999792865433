.data-row {
  display: flex;
  background-color: var(--even-row-background);

  margin: 0.5rem;
  padding: 0.2rem 0.5rem;

  line-height: 30px;

  &__label {
    flex-basis: 40%;
    max-width: 200px;
    padding-left: 0.5rem;

    font-family: $title-font;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    /* Don't wrap - show ellipsis if text exceeds width */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // Small tweak to vert pos of text
    transform: translate(0px, 2px);
  }

  &__value {
    flex-grow: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0.3rem 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: calc(100% - 200px);
  }

  &__value--nowrap {
    overflow: visible;
    white-space: wrap;
    line-height: 1.6rem;
    padding-bottom: 0.6rem;
    font-size: 0.9rem;
  }

  &.highlight {
    background-color: $title-color;
    color: white;
  } 

  .rounded-pill {
    margin-bottom: 5px;
  }

  &.bold {
    font-weight: bold;
  }

  &.clickable {
    cursor: pointer;
  }
}
