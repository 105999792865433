.user-role {
  &__superadmin {
    background-color: black;
    color: white;
  }
  &__admin {
    background-color: $brand-color;
    color: white;
  }
  &__eventstaff {
    background-color: $green;
    color: white;
  }
}
