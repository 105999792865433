.email-preview-popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  overflow: auto;

  display: none;

  &__content {
    width: 90%;
    height: 90%;
  }
}

.invite-guests iframe.email-preview-iframe-mobile,
.invite-schedule iframe.email-preview-iframe-mobile,
iframe.email-preview-iframe-mobile,
.email-preview-iframe-mobile {
  // flex: 0 0 410px; /* 393px is desired 'mobile' layout of email + 17px for scrollbar (doesnt need to be 100% accurate) */
  flex: 0 0 537px; /* testing 520px mobile email width */
  margin-left: auto;
  margin-right: auto;
}