@import "https://fonts.googleapis.com/css?family=Open+Sans&display=swap";
:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}

:root.dark {
  --dt-html-background: #212529;
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}

table.dataTable td.dt-control:before {
  color: #00000080;
  content: "▶";
  display: inline-block;
}

table.dataTable tr.dt-hasChild td.dt-control:before {
  content: "▼";
}

html.dark table.dataTable td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable td.dt-control:before, html.dark table.dataTable tr.dt-hasChild td.dt-control:before, :root[data-bs-theme="dark"] table.dataTable tr.dt-hasChild td.dt-control:before {
  color: #ffffff80;
}

table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting_asc_disabled, table.dataTable thead > tr > th.sorting_desc_disabled, table.dataTable thead > tr > td.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting_asc_disabled, table.dataTable thead > tr > td.sorting_desc_disabled {
  cursor: pointer;
  padding-right: 26px;
  position: relative;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: .125;
  font-size: .8em;
  line-height: 9px;
  display: block;
  position: absolute;
  right: 10px;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > td.sorting:before, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:before, table.dataTable thead > tr > td.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:before {
  content: "▲";
  content: "▲" / "";
  bottom: 50%;
}

table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting:after, table.dataTable thead > tr > td.sorting_asc:after, table.dataTable thead > tr > td.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc_disabled:after, table.dataTable thead > tr > td.sorting_desc_disabled:after {
  content: "▼";
  content: "▼" / "";
  top: 50%;
}

table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > td.sorting_asc:before, table.dataTable thead > tr > td.sorting_desc:after {
  opacity: .6;
}

table.dataTable thead > tr > th.sorting_desc_disabled:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > td.sorting_desc_disabled:after, table.dataTable thead > tr > td.sorting_asc_disabled:before {
  display: none;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

div.dataTables_scrollBody > table.dataTable > thead > tr > th:before, div.dataTables_scrollBody > table.dataTable > thead > tr > th:after, div.dataTables_scrollBody > table.dataTable > thead > tr > td:before, div.dataTables_scrollBody > table.dataTable > thead > tr > td:after {
  display: none;
}

div.dataTables_processing {
  text-align: center;
  z-index: 10;
  width: 200px;
  margin-top: -26px;
  margin-left: -100px;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.dataTables_processing > div:last-child {
  width: 80px;
  height: 15px;
  margin: 1em auto;
  position: relative;
}

div.dataTables_processing > div:last-child > div {
  background: #0d6efd;
  background: rgb(var(--dt-row-selected));
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 0;
}

div.dataTables_processing > div:last-child > div:first-child {
  animation: .6s infinite datatables-loader-1;
  left: 8px;
}

div.dataTables_processing > div:last-child > div:nth-child(2) {
  animation: .6s infinite datatables-loader-2;
  left: 8px;
}

div.dataTables_processing > div:last-child > div:nth-child(3) {
  animation: .6s infinite datatables-loader-2;
  left: 32px;
}

div.dataTables_processing > div:last-child > div:nth-child(4) {
  animation: .6s infinite datatables-loader-3;
  left: 56px;
}

@keyframes datatables-loader-1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes datatables-loader-3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes datatables-loader-2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable th.dt-left, table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right, table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify, table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap, table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th, table.dataTable thead td, table.dataTable tfoot th, table.dataTable tfoot td, table.dataTable thead th.dt-head-left, table.dataTable thead td.dt-head-left, table.dataTable tfoot th.dt-head-left, table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center, table.dataTable thead td.dt-head-center, table.dataTable tfoot th.dt-head-center, table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right, table.dataTable thead td.dt-head-right, table.dataTable tfoot th.dt-head-right, table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify, table.dataTable thead td.dt-head-justify, table.dataTable tfoot th.dt-head-justify, table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap, table.dataTable thead td.dt-head-nowrap, table.dataTable tfoot th.dt-head-nowrap, table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left, table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center, table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right, table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify, table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap, table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable {
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin: 0 auto;
}

table.dataTable thead th, table.dataTable tfoot th {
  font-weight: bold;
}

table.dataTable > thead > tr > th, table.dataTable > thead > tr > td {
  border-bottom: 1px solid #0000004d;
  padding: 10px;
}

table.dataTable > thead > tr > th:active, table.dataTable > thead > tr > td:active {
  outline: none;
}

table.dataTable > tfoot > tr > th, table.dataTable > tfoot > tr > td {
  border-top: 1px solid #0000004d;
  padding: 10px 10px 6px;
}

table.dataTable tbody tr {
  background-color: #0000;
}

table.dataTable tbody tr.selected > * {
  box-shadow: inset 0 0 0 9999px #0d6efde6;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .9);
  color: rgb(var(--dt-row-selected-text));
}

table.dataTable tbody tr.selected a {
  color: rgb(var(--dt-row-selected-link));
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 8px 10px;
}

table.dataTable.row-border > tbody > tr > th, table.dataTable.row-border > tbody > tr > td, table.dataTable.display > tbody > tr > th, table.dataTable.display > tbody > tr > td {
  border-top: 1px solid #00000026;
}

table.dataTable.row-border > tbody > tr:first-child > th, table.dataTable.row-border > tbody > tr:first-child > td, table.dataTable.display > tbody > tr:first-child > th, table.dataTable.display > tbody > tr:first-child > td {
  border-top: none;
}

table.dataTable.row-border > tbody > tr.selected + tr.selected > td, table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0d6efda6;
  border-top-color: rgba(var(--dt-row-selected), .65);
}

table.dataTable.cell-border > tbody > tr > th, table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid #00000026;
  border-right: 1px solid #00000026;
}

table.dataTable.cell-border > tbody > tr > th:first-child, table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid #00000026;
}

table.dataTable.cell-border > tbody > tr:first-child > th, table.dataTable.cell-border > tbody > tr:first-child > td {
  border-top: none;
}

table.dataTable.stripe > tbody > tr.odd > *, table.dataTable.display > tbody > tr.odd > * {
  box-shadow: inset 0 0 0 9999px #00000006;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-stripe), .023);
}

table.dataTable.stripe > tbody > tr.odd.selected > *, table.dataTable.display > tbody > tr.odd.selected > * {
  box-shadow: inset 0 0 0 9999px #0d6efdeb;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .923);
}

table.dataTable.hover > tbody > tr:hover > *, table.dataTable.display > tbody > tr:hover > * {
  box-shadow: inset 0 0 0 9999px #00000009;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .035);
}

table.dataTable.hover > tbody > tr.selected:hover > *, table.dataTable.display > tbody > tr.selected:hover > * {
  box-shadow: inset 0 0 0 9999px #0d6efd !important;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), 1) !important;
}

table.dataTable.order-column > tbody tr > .sorting_1, table.dataTable.order-column > tbody tr > .sorting_2, table.dataTable.order-column > tbody tr > .sorting_3, table.dataTable.display > tbody tr > .sorting_1, table.dataTable.display > tbody tr > .sorting_2, table.dataTable.display > tbody tr > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000005;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .019);
}

table.dataTable.order-column > tbody tr.selected > .sorting_1, table.dataTable.order-column > tbody tr.selected > .sorting_2, table.dataTable.order-column > tbody tr.selected > .sorting_3, table.dataTable.display > tbody tr.selected > .sorting_1, table.dataTable.display > tbody tr.selected > .sorting_2, table.dataTable.display > tbody tr.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdea;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .919);
}

table.dataTable.display > tbody > tr.odd > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0000000e;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .054);
}

table.dataTable.display > tbody > tr.odd > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0000000c;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .047);
}

table.dataTable.display > tbody > tr.odd > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.odd > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0000000a;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .039);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdf3;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .954);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efdf1;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .947);
}

table.dataTable.display > tbody > tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.odd.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdef;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .939);
}

table.dataTable.display > tbody > tr.even > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #00000005;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .019);
}

table.dataTable.display > tbody > tr.even > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #00000003;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .011);
}

table.dataTable.display > tbody > tr.even > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000001;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-column-ordering), .003);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_1, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdea;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .919);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_2, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efde8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .911);
}

table.dataTable.display > tbody > tr.even.selected > .sorting_3, table.dataTable.order-column.stripe > tbody > tr.even.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efde6;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .903);
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #00000015;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .082);
}

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #00000013;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .074);
}

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #00000010;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-hover), .062);
}

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  box-shadow: inset 0 0 0 9999px #0d6efdfa;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .982);
}

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  box-shadow: inset 0 0 0 9999px #0d6efdf8;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .974);
}

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  box-shadow: inset 0 0 0 9999px #0d6efdf5;
  box-shadow: inset 0 0 0 9999px rgba(var(--dt-row-selected), .962);
}

table.dataTable.no-footer {
  border-bottom: 1px solid #0000004d;
}

table.dataTable.compact thead th, table.dataTable.compact thead td, table.dataTable.compact tfoot th, table.dataTable.compact tfoot td, table.dataTable.compact tbody th, table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable th, table.dataTable td {
  box-sizing: content-box;
}

.dataTables_wrapper {
  clear: both;
  position: relative;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_length select {
  color: inherit;
  background-color: #0000;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 4px;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  color: inherit;
  background-color: #0000;
  border: 1px solid #aaa;
  border-radius: 3px;
  margin-left: 3px;
  padding: 5px;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: .755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: .25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid #0000;
  border-radius: 2px;
  min-width: 1.5em;
  margin-left: 2px;
  padding: .5em 1em;
  display: inline-block;
  color: inherit !important;
  text-decoration: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: -moz-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: -ms-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: -moz-linear-gradient(top, #e6e6e60d 0%, #0000000d 100%);
  background: linear-gradient(#e6e6e60d 0%, #0000000d 100%);
  border: 1px solid #0000004d;
  color: inherit !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  box-shadow: none;
  background: none;
  border: 1px solid #0000;
  color: #666 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(#585858 0%, #111 100%);
  border: 1px solid #111;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  background: linear-gradient(#2b2b2b 0%, #0c0c0c 100%);
  outline: none;
  box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
  color: inherit;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #0000004d;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable, .dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  content: "";
  clear: both;
  height: 0;
  display: block;
}

@media screen and (width <= 767px) {
  .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_paginate {
    margin-top: .5em;
  }
}

@media screen and (width <= 640px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_filter {
    margin-top: .5em;
  }
}

html.dark {
  --dt-row-hover: 255, 255, 255;
  --dt-row-stripe: 255, 255, 255;
  --dt-column-ordering: 255, 255, 255;
}

html.dark table.dataTable > thead > tr > th, html.dark table.dataTable > thead > tr > td {
  border-bottom: 1px solid #595b5e;
}

html.dark table.dataTable > thead > tr > th:active, html.dark table.dataTable > thead > tr > td:active {
  outline: none;
}

html.dark table.dataTable > tfoot > tr > th, html.dark table.dataTable > tfoot > tr > td {
  border-top: 1px solid #595b5e;
}

html.dark table.dataTable.row-border > tbody > tr > th, html.dark table.dataTable.row-border > tbody > tr > td, html.dark table.dataTable.display > tbody > tr > th, html.dark table.dataTable.display > tbody > tr > td {
  border-top: 1px solid #404346;
}

html.dark table.dataTable.row-border > tbody > tr.selected + tr.selected > td, html.dark table.dataTable.display > tbody > tr.selected + tr.selected > td {
  border-top-color: #0d6efda6;
  border-top-color: rgba(var(--dt-row-selected), .65);
}

html.dark table.dataTable.cell-border > tbody > tr > th, html.dark table.dataTable.cell-border > tbody > tr > td {
  border-top: 1px solid #404346;
  border-right: 1px solid #404346;
}

html.dark table.dataTable.cell-border > tbody > tr > th:first-child, html.dark table.dataTable.cell-border > tbody > tr > td:first-child {
  border-left: 1px solid #404346;
}

html.dark .dataTables_wrapper .dataTables_filter input, html.dark .dataTables_wrapper .dataTables_length select {
  background-color: var(--dt-html-background);
  border: 1px solid #fff3;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #ffffff26;
  border: 1px solid #595b5e;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, html.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #666 !important;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #353535;
  border: 1px solid #353535;
}

html.dark .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #3a3a3a;
}

table.dataTable > tbody > tr > .selected {
  color: #fff;
  background-color: #0d6efde6;
}

table.dataTable > tbody > tr > td.select-checkbox, table.dataTable > tbody > tr > th.select-checkbox {
  position: relative;
}

table.dataTable > tbody > tr > td.select-checkbox:before, table.dataTable > tbody > tr > td.select-checkbox:after, table.dataTable > tbody > tr > th.select-checkbox:before, table.dataTable > tbody > tr > th.select-checkbox:after {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

table.dataTable > tbody > tr > td.select-checkbox:before, table.dataTable > tbody > tr > th.select-checkbox:before {
  content: " ";
  border: 1px solid #000;
  border-radius: 3px;
  margin-top: -6px;
  margin-left: -6px;
}

table.dataTable > tbody > tr.selected > td.select-checkbox:before, table.dataTable > tbody > tr.selected > th.select-checkbox:before {
  border: 1px solid #fff;
}

table.dataTable > tbody > tr.selected > td.select-checkbox:after, table.dataTable > tbody > tr.selected > th.select-checkbox:after {
  content: "✓";
  text-align: center;
  margin-top: -12px;
  margin-left: -6px;
  font-size: 20px;
}

table.dataTable.compact > tbody > tr > td.select-checkbox:before, table.dataTable.compact > tbody > tr > th.select-checkbox:before {
  margin-top: -12px;
}

table.dataTable.compact > tbody > tr.selected > td.select-checkbox:after, table.dataTable.compact > tbody > tr.selected > th.select-checkbox:after {
  margin-top: -16px;
}

div.dataTables_wrapper span.select-info, div.dataTables_wrapper span.select-item {
  margin-left: .5em;
}

html.dark table.dataTable > tbody > tr > td.select-checkbox:before, html.dark table.dataTable > tbody > tr > th.select-checkbox:before, html[data-bs-theme="dark"] table.dataTable > tbody > tr > td.select-checkbox:before, html[data-bs-theme="dark"] table.dataTable > tbody > tr > th.select-checkbox:before {
  border: 1px solid #fff9;
}

@media screen and (width <= 640px) {
  div.dataTables_wrapper span.select-info, div.dataTables_wrapper span.select-item {
    margin-left: 0;
    display: block;
  }
}

:root {
  --background: #e1e1e1;
  --section-background-1: #f1f3f1;
  --section-background-2: #fff;
  --even-row-background: #f7fafa;
  --odd-row-background: #fff;
  --column-line-color: #e5e7e5;
  --input-border-color: #e6e6e4;
  --placeholder-color: #818485;
  --button-height: 42px;
  --brand-color: #cb1e54;
  --red: #c70101;
  --white: #fff;
  --green: #06b7a5;
  --darkgreen: #047c70;
  --blue: #7087bd;
  --darkblue: #4f6aab;
  --base-color: #333;
}

html, body {
  background: var(--background);
  text-rendering: optimizelegibility;
  color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  font-size: 14px;
  overflow-x: hidden;
}

body {
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  padding-right: 0 !important;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  font-size: 1.2rem;
}

h1, h2, h3, h4, h5, th, button {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
}

h1 {
  color: #cb1e54;
}

h3 {
  margin-top: 2rem;
}

section {
  background-color: var(--section-background-1);
  padding: 1rem 2rem;
}

body > section {
  margin: 1rem 2rem;
}

.section-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.section-header h2 {
  color: #cb1e54;
  margin: 0;
  font-size: 1.35rem;
  font-weight: 600;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="file"]:focus, textarea:focus {
  outline: 2px solid #cb1e54;
}

.close {
  font-size: 2rem;
  font-weight: 400;
  transform: translateY(-5px);
}

h2.alert__title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #cb1e54;
  margin-bottom: 1.1rem;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: 1.4rem;
}

@media (width >= 768px) {
  .navbar-nav > li {
    float: left;
  }
}

.navbar {
  min-height: 70px;
}

.navbar-nav > li > a {
  letter-spacing: 1.2px;
  font-weight: 600;
}

i.i18n {
  color: #0000;
  letter-spacing: -99px;
}

@media only screen and (width <= 1024px) {
  body > section {
    margin: .8rem .8rem 0;
  }

  section {
    padding: .8rem;
  }
}

@media only screen and (width <= 768px) {
  body > section {
    margin: .8rem 0 0;
  }

  section {
    padding: .8rem 1.1rem;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.row-gap {
  row-gap: 1rem;
}

.pad-span {
  margin-left: .5rem;
}

.pill-active {
  color: #fff;
  background-color: #cb1e54;
}

.btn.btn.hidden, .hidden {
  display: none;
}

body.dark {
  --background: #020406;
  --section-background-1: #1e222a;
  --section-background-2: #0e141a;
  --even-row-background: #292e34;
  --odd-row-background: #30343c;
  --column-line-color: #1b2129;
  --input-border-color: #2f333b;
  --placeholder-color: #2f333b;
  --button-height: 42px;
  --brand-color: #cb1e54;
  --red: #c70101;
  --white: #fff;
  --green: #06b7a5;
  --darkgreen: #047c70;
  --blue: #7087bd;
  --darkblue: #4f6aab;
  --header-bar-background: var(--section-background-1);
  --title-color: var(--brand-color);
  --input-background: var(--section-background-2);
  --base-color: #dfe0de;
}

.pill {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #555;
  background-color: #eee;
  border: 2px solid #0000000d;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 26px;
  padding: 0;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .75rem;
  font-weight: bold;
  display: inline-flex;
  position: relative;
}

.pill.pill-active {
  color: #fff;
  width: "100%";
  display: "flex";
  background-color: #7087bd;
}

.alert {
  border-radius: 0;
}

.swal2-container .swal2-styled.btn.btn {
  margin: .2rem 1rem;
  border: 1px solid #e2e2e2 !important;
}

.swal2-container .swal2-styled.btn.btn:not(:disabled):hover:before {
  display: none;
}

.swal2-container .swal2-styled.btn.btn:not(:disabled):hover {
  color: #fff;
  background-color: #cb1e54;
}

.login {
  background: #fff;
  flex-grow: 1;
  height: 100vh;
  max-height: 100vh;
  display: flex;
}

.login__right {
  flex-direction: column;
  flex: 1 0 50%;
  justify-content: center;
  align-items: center;
  min-width: 500px;
  display: flex;
}

.login__left {
  flex-grow: 1;
}

.login h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.login__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.login__logo {
  image-rendering: optimizequality;
  object-fit: contain;
  width: 220px;
  margin-bottom: 6rem;
}

.login__inner {
  border-top: 2px solid #cb1e54;
  justify-content: center;
  width: 400px;
  max-width: 100%;
  padding: 2.5rem 0;
}

.login__extras {
  justify-content: space-between;
  display: flex;
}

.login .input-group {
  margin-bottom: 2rem;
}

.login__buttons {
  justify-content: center;
  margin-top: 1rem;
  display: flex;
}

.login__error-container {
  color: #c70101;
  text-align: center;
  height: 2rem;
}

.login input, .login .input-group-text {
  border-color: #818485 !important;
}

.login input:-webkit-autofill {
  border-color: #818485 !important;
}

.login input:-webkit-autofill:hover {
  border-color: #818485 !important;
}

.login input:-webkit-autofill:focus {
  border-color: #818485 !important;
}

.login__forgot-password {
  cursor: pointer;
}

.login__forgot-password:hover {
  color: #cb1e54;
}

@media (width <= 767px) {
  .login__left {
    display: none;
  }

  .login__right {
    min-width: 0;
  }

  .login__inner {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.form-control.table__filter-select {
  margin: 0 1rem 0 0 !important;
}

input[type="text"], input[type="search"], input[type="password"], select, select.form-control, textarea.form-control {
  border: solid 1px var(--input-border-color);
  border-radius: 0;
  outline: none;
  padding: .5rem 1rem;
}

input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus, select:focus, select.form-control:focus, textarea.form-control:focus {
  box-shadow: none;
  border-color: #0000;
  outline: 2px solid #cb1e54;
}

input[type="text"].field-error, input[type="search"].field-error, input[type="password"].field-error, select.field-error, select.form-control.field-error, textarea.form-control.field-error {
  border-color: #cb1e54;
}

input[type="text"]::placeholder, input[type="search"]::placeholder, input[type="password"]::placeholder, select::placeholder, select.form-control::placeholder, textarea.form-control::placeholder {
  color: var(--placeholder-color);
}

select, select.form-control {
  padding-top: 0;
  padding-bottom: 0;
}

.input-group .fas {
  color: #cb1e54;
}

.input-group .input-group-text {
  background: #fff;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  display: flex;
}

.input-group input[type="text"], .input-group input[type="password"], .input-group select {
  min-height: 40px;
}

.form-section {
  border: 1px solid #ccc;
  margin: 0 0 .4rem;
  padding: .35rem .5rem;
}

.form-section__label {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-left: .5rem;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .8rem;
  font-weight: 600;
  overflow: hidden;
}

.form-group, .form-inline {
  background-color: var(--even-row-background);
  margin: 0 0 .4rem;
  padding: .35rem .5rem;
}

.form-group label, .form-inline label {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding-left: .5rem;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .8rem;
  font-weight: 600;
  overflow: hidden;
}

.form-group input, .form-group select, .form-inline input, .form-inline select {
  flex-grow: 1;
}

.form-group.no-margin, .form-inline.no-margin {
  margin: 0;
}

.form-group.no-bottom-margin, .form-inline.no-bottom-margin {
  margin-bottom: 0;
}

.form-group.no-top-margin, .form-inline.no-top-margin {
  margin-top: 0;
}

.form-group label {
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: .3rem;
  margin-bottom: .2rem;
}

.form-group input, .form-group select {
  flex-grow: 1;
}

.custom-file-label, .input-group-text {
  border-color: var(--input-border-color);
  border-radius: 0;
}

input:-webkit-autofill {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

input:-webkit-autofill:hover {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

input:-webkit-autofill:focus {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

textarea:-webkit-autofill {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

textarea:-webkit-autofill:hover {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

textarea:-webkit-autofill:focus {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

select:-webkit-autofill {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

select:-webkit-autofill:hover {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

select:-webkit-autofill:focus {
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Open Sans, Helvetica, Arial, sans-serif;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

.form-inline .btn.btn {
  background: 0;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  margin: 2px;
  padding: 0;
  display: flex;
}

.form-inline .btn.btn i.fas {
  margin: 0;
  padding: 0;
  transform: translate(1px);
}

.flex-row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.flex-grow-0 {
  flex-grow: 0;
}

@media only screen and (width <= 768px) {
  .form-inline label {
    text-align: left;
    justify-content: flex-start;
    margin-bottom: .3rem;
  }
}

.modal-header {
  background-color: var(--section-background-1);
  border-bottom: none;
  align-items: center;
}

.modal-title {
  margin: 0 0 0 1rem;
}

.modal-content {
  border-radius: 0;
}

.modal-footer {
  justify-content: flex-start;
}

.modal-footer button {
  margin-right: .25rem !important;
}

.loader {
  z-index: 9999;
  opacity: .7;
  cursor: wait;
  background-color: #fff;
  background-image: url("loader.b3774040.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 4rem;
  position: fixed;
  inset: 0;
}

.swal2-styled.btn.btn, .btn.btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  zoom: 1;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
  color: inherit;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  outline: none;
  min-height: 42px;
  margin: 0;
  padding: 0 2rem 0 1.7rem;
  font-weight: bold;
  line-height: 40px;
  text-decoration: none;
  transition: background .2s, color .2s, border .2s;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: none !important;
}

.swal2-styled.btn.btn:focus, .btn.btn:focus {
  border-color: #000c;
}

.swal2-styled.btn.btn:before, .btn.btn:before {
  content: "";
  background: #cb1e54;
  width: 100%;
  height: 100%;
  transition: transform .6s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 105%, 0);
}

.swal2-styled.btn.btn:not(:disabled):hover, .swal2-styled.btn.btn:not(:disabled).btn--selected, .btn.btn:not(:disabled):hover, .btn.btn:not(:disabled).btn--selected {
  color: #fff;
}

.swal2-styled.btn.btn:not(:disabled):hover:before, .swal2-styled.btn.btn:not(:disabled).btn--selected:before, .btn.btn:not(:disabled):hover:before, .btn.btn:not(:disabled).btn--selected:before {
  transform: translate3d(0, 0, 0);
}

.swal2-styled.btn.btn.btn-primary, .btn.btn.btn-primary {
  color: #fff;
  background: #cb1e54;
  border-color: #cb1e54;
}

.swal2-styled.btn.btn.btn-primary:before, .btn.btn.btn-primary:before {
  background: #fff;
}

.swal2-styled.btn.btn.btn-primary:not(:disabled):hover, .swal2-styled.btn.btn.btn-primary:not(:disabled).btn--selected, .btn.btn.btn-primary:not(:disabled):hover, .btn.btn.btn-primary:not(:disabled).btn--selected {
  color: #cb1e54;
}

.swal2-styled.btn.btn.btn-primary:focus, .btn.btn.btn-primary:focus {
  border-color: #000c;
}

.swal2-styled.btn.btn.btn-primary:focus:hover, .swal2-styled.btn.btn.btn-primary:focus.btn--selected, .btn.btn.btn-primary:focus:hover, .btn.btn.btn-primary:focus.btn--selected {
  border-color: #cb1e54;
}

.swal2-styled.btn.btn.btn-small, .btn.btn.btn-small {
  min-height: 30px;
  padding-left: 1rem;
  padding-right: .85rem;
  line-height: 30px;
}

.swal2-styled.btn.btn.disabled, .swal2-styled.btn.btn:disabled, .btn.btn.disabled, .btn.btn:disabled {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}

.swal2-styled.btn.btn label, .swal2-styled.btn.btn span, .swal2-styled.btn.btn i, .btn.btn label, .btn.btn span, .btn.btn i {
  box-sizing: border-box;
  vertical-align: middle;
  padding: 1px 0 3px;
  font-size: 16px;
  line-height: 112%;
  display: inline-block;
  position: relative;
}

.swal2-styled.btn.btn i.fas, .btn.btn i.fas {
  margin-right: 1rem;
}

button.close {
  outline: none;
  padding: 1rem;
}

a button {
  text-decoration: none;
}

@media only screen and (width <= 768px) {
  .swal2-styled.btn.btn, .btn.btn {
    padding: 0 1.5rem;
  }
}

.btn.btn.email__download-html, .email__download-html {
  margin-left: auto;
}

.data-row {
  background-color: var(--even-row-background);
  margin: .5rem;
  padding: .2rem .5rem;
  line-height: 30px;
  display: flex;
}

.data-row__label {
  text-transform: uppercase;
  letter-spacing: .5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-basis: 40%;
  max-width: 200px;
  padding-left: .5rem;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .8rem;
  font-weight: 600;
  overflow: hidden;
  transform: translate(0, 2px);
}

.data-row__value {
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-word;
  flex-grow: 2;
  align-items: center;
  max-width: calc(100% - 200px);
  padding: .3rem .5rem;
  line-height: 1.4rem;
  display: flex;
  overflow: hidden;
}

.data-row__value--nowrap {
  white-space: wrap;
  padding-bottom: .6rem;
  font-size: .9rem;
  line-height: 1.6rem;
  overflow: visible;
}

.data-row.highlight {
  color: #fff;
  background-color: #cb1e54;
}

.data-row .rounded-pill {
  margin-bottom: 5px;
}

.data-row.bold {
  font-weight: bold;
}

.data-row.clickable {
  cursor: pointer;
}

table.datatable.datatable {
  border-bottom: none;
  max-width: 100%;
}

table.datatable.datatable thead th {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0000;
  border-bottom: none;
  overflow: hidden;
}

table.datatable.datatable thead .sorting {
  background-image: none;
}

table.datatable.datatable tbody tr:hover td:not(.dataTables_empty) {
  color: #fff;
  cursor: pointer;
  background-color: #cb1e54;
  border-color: #0000;
}

table.datatable.datatable tbody tr:hover td:not(.dataTables_empty) * {
  cursor: pointer;
}

table.datatable.datatable tbody tr:nth-child(2n) {
  background: var(--even-row-background);
}

table.datatable.datatable tbody tr:nth-child(odd) {
  background: var(--odd-row-background);
}

table.datatable.datatable tbody td {
  border-left: solid 1px var(--column-line-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: .7rem 1rem;
  overflow: hidden;
}

table.datatable.datatable tbody td:first-child {
  border: none;
}

table.datatable.datatable tr:not(:hover) td.dt-highlight {
  color: #cb1e54;
}

.dataTables_info {
  opacity: .7;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: .4rem;
  margin-bottom: .4rem;
  margin-left: 1rem;
  padding-top: 0;
  font-size: .85rem;
}

table.selectable.dataTable tbody tr:hover td:not(.dataTables_empty) {
  color: var(--base-color);
  background-color: #cb1e541a;
  border-color: #0000;
}

table.selectable.dataTable tbody tr.selected td:not(.dataTables_empty), table.selectable.dataTable tbody tr.selected:hover td:not(.dataTables_empty) {
  color: #fff;
  background-color: #cb1e54;
  border-color: #0000;
}

table.selectable.dataTable tbody td.select-checkbox:before, table.selectable.dataTable tbody th.select-checkbox:before {
  border: solid 1px var(--input-border-color);
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom-color: #0000;
}

.dataTables_filter {
  position: relative;
}

.dataTables_filter label:before {
  content: "";
  color: #cb1e54;
  background-color: #fff;
  border-right: 1px solid #e6e6e4;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 40px;
  margin: 0;
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  font-weight: 900;
  line-height: 36px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 2px;
}

.dataTables_filter input {
  height: 44px !important;
  padding-left: calc(42px + 1rem) !important;
}

.dataTables_wrapper .dataTables_scrollBody {
  background-color: #f7fafa;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0;
}

.dataTables_wrapper .dataTables_info {
  padding: .7rem 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #cb1e54;
  border: none;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #fff;
  border-color: #0000;
  color: var(--base-color) !important;
}

.dataTables_wrapper .dataTables_paginate {
  text-transform: uppercase;
}

.dataTables_wrapper .select-info {
  line-height: 1.4rem;
}

.dataTables_wrapper .select-info .select-item:first-child {
  color: #fff;
  float: left;
  background-color: #cb1e54;
  margin-right: 1rem;
  padding: 0 1rem;
}

.dataTables_wrapper th.select-checkbox {
  text-align: center;
}

.dataTables_wrapper th.select-checkbox .fas {
  color: #cb1e54;
}

.dt-wrap {
  min-width: 400px;
  max-width: 400px;
  white-space: normal !important;
}

@media only screen and (width <= 1024px) {
  table.datatable.datatable tbody td {
    padding: .5rem;
  }
}

.user-role__superadmin {
  color: #fff;
  background-color: #000;
}

.user-role__admin {
  color: #fff;
  background-color: #cb1e54;
}

.user-role__eventstaff {
  color: #fff;
  background-color: #06b7a5;
}

.add-guests.side-panel {
  width: 100%;
  position: fixed;
}

.add-guests.side-panel__table-buttons {
  margin-top: .5rem;
}

.add-guests.side-panel__top-bar {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.add-guests.side-panel__top-bar .btn {
  margin-right: 1rem;
}

.add-guests.side-panel__top-bar .separator {
  flex-grow: 1;
}

.add-guests.side-panel .dataTables_filter {
  align-items: initial;
  flex-wrap: wrap;
  column-gap: 1rem;
  margin-top: .5rem;
  display: flex;
}

.add-guests.side-panel .dataTables_filter label {
  flex-grow: 1;
  width: auto;
  min-width: 280px;
}

.add-guests.side-panel .dataTables_filter > div {
  width: auto;
  min-width: 280px;
}

.add-guests.side-panel .dataTables_filter > div select {
  min-height: 44px;
  width: 100% !important;
  margin: 0 !important;
}

.header-bar {
  background-color: #fff;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 0 4rem;
  display: flex;
}

.header-bar__spacer {
  flex-grow: 1;
}

.header-bar__separator {
  border-left: 1px solid #80808080;
  height: 3rem;
  margin: 0 1rem;
}

.header-bar__tab {
  color: #cb1e54;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-top: 3px solid #0000;
  border-bottom: 3px solid #0000;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: 1.35rem;
  font-weight: 600;
  text-decoration: none;
  transition: border-color .3s;
  display: flex;
}

.header-bar__tab * {
  text-decoration: none;
}

.header-bar__tab:hover {
  color: #cb1e54;
  text-decoration: none;
}

.header-bar__tab .fas {
  margin-right: 2rem;
}

.header-bar__tab * {
  cursor: pointer;
}

.header-bar__tab:hover {
  border-bottom-color: #cb1e5433;
}

.header-bar__tab--selected {
  border-bottom-color: #cb1e54 !important;
}

.header-bar--centered {
  justify-content: center;
}

.header-bar img {
  object-fit: contain;
  height: 60%;
}

.header-bar .btn.btn {
  border: none !important;
}

.header-bar #header-bar__configuration {
  color: inherit;
}

.header-bar #header-bar__configuration .btn {
  padding: 0 1.2rem;
}

.header-bar #header-bar__configuration .btn i {
  margin: 0;
}

@media only screen and (width <= 768px) {
  .header-bar__tab {
    letter-spacing: 0;
    font-size: 0;
  }

  .header-bar__tab i.fas {
    margin-right: 0;
    font-size: 1.35rem;
  }
}

.plus-badge {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #cb1e54;
  border: 2px solid #0000000d;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 26px;
  padding: 0;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .75rem;
  font-weight: bold;
  display: inline-flex;
  position: absolute;
  right: -1rem;
}

.plus-badge.linked {
  background-color: #7087bd;
}

.schedule-sent {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #cb1e54;
  border: 2px solid #0000000d;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 26px;
  padding: 0;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .75rem;
  font-weight: bold;
  display: inline-flex;
  position: absolute;
  right: -1rem;
}

.side-panel {
  opacity: 1;
  z-index: 1;
  background-color: #fff;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  max-height: 100%;
  transition: transform .2s, opacity .2s;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  transform: translateX(0);
  box-shadow: -10px 0 24px #0000000a, -6px 0 6px #00000014;
}

.side-panel.hidden {
  opacity: 0;
  transform: translateX(100px);
}

.side-panel__header {
  background-color: var(--section-background-1);
  align-items: center;
  height: 4rem;
  padding: .4rem 2rem 0;
  display: flex;
}

.side-panel__header h2 {
  flex-grow: 1;
  margin: 0;
}

.side-panel .data-row {
  margin-left: 0;
  margin-right: 0;
}

.side-panel__content {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.side-panel__content-left {
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  padding: .2rem 0 .2rem 1rem;
  display: flex;
}

.side-panel__content-right {
  flex-direction: column;
  flex-shrink: 0;
  padding: .7rem 1rem;
  display: flex;
}

.side-panel__content-right .btn {
  margin-bottom: .7rem;
}

.side-panel__content h3 {
  margin-left: 1rem;
}

.event-list__top-bar {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.event-list__top-bar .btn {
  margin-right: 1rem;
}

.event-list__top-bar .separator {
  flex-grow: 1;
}

#event-list_filter {
  width: 300px;
}

#event-list_filter label {
  margin: 0;
}

.guest-list {
  width: 1200px;
}

.guest-list__filter-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

.guest-list__top-bar {
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  grid-template: auto / .5fr 1fr;
  align-items: flex-start;
  margin-bottom: 0;
  display: grid;
}

.guest-list__top-bar .btn {
  margin-top: 1rem;
  margin-right: 1rem;
}

.guest-list__top-bar .btn:last-child {
  margin-right: 0;
}

.guest-list__main {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow: hidden;
}

.guest-list__main > .dataTables_wrapper {
  flex-grow: 1;
  width: 1px;
}

.guest-list__datatable {
  flex-grow: 1;
}

.guest-list__left {
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  display: flex;
}

.guest-list__search-bar {
  display: flex;
}

.guest-list__search-bar .dataTables_filter {
  flex-grow: 1;
  width: 1px;
}

.guest-list__status-selection {
  width: auto;
  min-width: 17rem;
  height: 44px;
  margin-right: 1rem;
}

.guest-list__side-column {
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 350px;
  max-width: 35%;
  padding: 0 0 0 2rem;
  display: flex;
}

.guest-list__side-column .btn {
  margin-bottom: .8rem;
}

.guest-list__side-column > h3:first-of-type {
  margin-top: .8rem;
}

.guest-list__side-column .data-row__label {
  flex-grow: 1;
  flex-basis: auto;
  max-width: none;
}

.guest-list__side-column .data-row__value {
  text-align: right;
  flex-grow: 1;
  flex-basis: auto;
  justify-content: flex-end;
  max-width: none;
}

.guest-list__side-column .data-row__disabled {
  opacity: .4;
}

.guest-list__vip-badge {
  margin-right: 7px;
  display: block;
}

.guest-list__vip-badge svg {
  width: 22px;
  height: 20px;
  display: block;
}

.member-list__top-bar {
  flex-direction: row;
  align-items: stretch;
  display: flex;
}

.member-list__top-bar .btn {
  margin-right: 1rem;
}

.member-list__top-bar .separator {
  flex-grow: 1;
}

.member-list__filter-wrapper {
  margin: 0 1rem 0 0 !important;
}

#member-list_filter {
  width: 300px;
}

#member-list_filter label {
  margin: 0;
}

select.form-control.table__filter-select, .table__filter-select {
  height: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.members-list-toggle {
  cursor: pointer;
  margin: 0 .5rem .5rem;
  display: block;
}

@media (width <= 1024px) {
  .member-list__top-bar .btn {
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 49px;
    padding: 0;
    display: flex;
  }

  .member-list__top-bar .btn label, .member-list__top-bar .btn span {
    display: none;
  }

  .member-list__top-bar .btn i.fas {
    margin: auto;
  }

  .member-list__top-bar .separator {
    display: none;
  }

  .member-list__top-bar #member-list_filter {
    flex-grow: 1;
  }

  .member-list__top-bar #member-list_filter label, .member-list__top-bar #member-list_filter input {
    width: 100%;
  }
}

.email-member .modal-dialog {
  width: 85vw;
  max-width: 100%;
}

.email-member .modal-body {
  height: 77vh;
  display: flex;
}

.email-member iframe {
  border: 1px solid #e6e6e4;
  flex-grow: 1;
  margin: 0 1rem;
}

.email-member__form {
  width: 340px;
  overflow: auto;
}

button#email-member__image {
  height: 100%;
}

.image-browser__placeholder {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.email-member__handlerbar-trigger, .btn.email-member__handlerbar-trigger {
  margin-bottom: 2px;
}

.email-member__members ul {
  margin-bottom: 0;
  padding-bottom: 4px;
  padding-left: 20px;
}

@media only screen and (width <= 1024px) {
  .email-member iframe {
    display: none;
  }

  .email-member__form {
    width: 100%;
  }

  .email-member .modal-body {
    height: auto;
  }
}

.day-selector .form-inline:not(:last-child) {
  margin-bottom: 0;
}

.day-selector .form-inline:not(:first-child) {
  padding-top: 0;
}

.day-selector .day-selector__mm {
  flex-grow: 1;
  max-width: none;
  transform: translateX(-1px);
}

.day-selector .day-selector__yyyy {
  transform: translateX(-2px);
}

.search-field {
  flex-grow: 1;
  height: 42px;
  margin-right: 1rem;
  position: relative;
}

.search-field input {
  width: 100%;
  height: 44px;
  padding-left: calc(42px + 1rem);
}

.search-field:before {
  content: "";
  color: #cb1e54;
  background-color: #fff;
  border-right: 1px solid #e6e6e4;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 40px;
  margin: 0;
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  font-weight: 900;
  line-height: 36px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 2px;
}

.user-details {
  width: 800px;
}

.configuration__users-section {
  position: relative;
}

.invite-guests .modal-dialog, .invite-schedule .modal-dialog {
  width: 85vw;
  max-width: 100%;
}

.invite-guests .modal-body, .invite-schedule .modal-body {
  height: 77vh;
  display: flex;
}

.invite-guests iframe, .invite-schedule iframe {
  border: 1px solid #e6e6e4;
  flex-grow: 1;
  margin: 0 1rem;
}

.invite-guests__form, .invite-schedule__form {
  width: 340px;
  overflow: auto;
}

button#invite-guests__image {
  height: 100%;
}

@media only screen and (width <= 1024px) {
  .invite-guests iframe, .invite-schedule iframe {
    display: none;
  }

  .invite-guests__form, .invite-schedule__form {
    width: 100%;
  }

  .invite-guests .modal-body, .invite-schedule .modal-body {
    height: auto;
  }
}

.member-details {
  opacity: 1;
  background-color: #fff;
  width: 900px;
  max-width: 100%;
  max-height: 100%;
  transition: transform .2s, opacity .2s;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  transform: translateX(0);
  box-shadow: -10px 0 24px #0000000a, -6px 0 6px #00000014;
}

.member-details__header {
  background-color: var(--section-background-1);
  align-items: center;
  height: 4rem;
  padding: .4rem 2rem 0;
  display: flex;
}

.member-details__header h2 {
  flex-grow: 1;
  margin: 0;
}

.member-details .data-row {
  margin-left: 0;
  margin-right: 0;
}

.member-details__content {
  flex-direction: row;
  display: flex;
}

.member-details__content-left {
  flex-grow: 1;
  padding: .2rem 0 .2rem 1rem;
}

.member-details__content-right {
  flex-direction: column;
  flex-shrink: 0;
  padding: .7rem 1rem;
  display: flex;
}

.member-details__content-right .btn {
  margin-bottom: .7rem;
}

.member-details h3 {
  margin-left: 1rem;
}

.member-details.hidden {
  opacity: 0;
  transform: translateX(100px);
}

.member-details .side-panel__header h2 {
  padding-left: 42px;
  position: relative;
}

.vip-badge {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.vip-badge svg {
  width: 31px;
  height: 28px;
  display: block;
}

.email-templates__row {
  background-color: var(--even-row-background);
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.email-templates__row > :first-child {
  text-transform: uppercase;
  letter-spacing: 1px;
  flex-grow: 1;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
}

.email-templates__row .btn {
  margin-left: 1rem;
}

.email-templates__row:nth-child(odd) {
  background-color: var(--odd-row-background);
}

.event-guest-list__top-bar {
  flex-direction: row;
  display: flex;
}

.event-guest-list__top-bar h2 {
  color: #cb1e54;
  font-size: 2rem;
  font-weight: bold;
}

.event-guest-list__top-bar h2, .event-guest-list__top-bar h3 {
  margin-top: .2rem;
  margin-bottom: .2rem;
}

.event-guest-list__main {
  flex-direction: column;
  flex-grow: 1;
  margin-top: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.event-guest-list__main > .dataTables_wrapper {
  flex-grow: 1;
  width: 100%;
  height: 1px;
}

.event-guest-list__event-details {
  flex-grow: 1;
}

.event-guest-list__summary {
  white-space: nowrap;
  flex-grow: 1;
  grid-template: 45px / 1fr 1fr;
  align-items: center;
  min-width: 300px;
  max-width: 440px;
  display: grid;
}

.event-guest-list__summary .data-row__label {
  flex-grow: 1;
  flex-basis: auto;
  max-width: none;
}

.event-guest-list__summary .data-row__value {
  text-align: right;
  flex-grow: 1;
  flex-basis: auto;
  justify-content: flex-end;
  max-width: none;
}

.dataTables_filter, .dataTables_filter label, .dataTables_filter input {
  width: 100%;
}

.dataTables_wrapper, .dataTables_scroll {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.dataTables_scrollBody {
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
  display: flex;
}

.control_bar {
  display: flex;
}

⁠.event-guest-list__summary {
  white-space: nowrap;
  flex-grow: 1;
  grid-template: 45px / 1fr 1fr;
  align-items: center;
  max-width: 440px;
  display: grid;
}

.event-guest-list__agency-list.form-control, .event-guest-list__market-responsible-list.form-control {
  width: calc(100% - 14px);
  margin-left: 7px;
}

.language-selector__option {
  cursor: pointer;
  border-bottom: 3px solid #0000;
  padding-right: .5rem;
  transition: border-color .3s;
}

.language-selector__option:hover {
  border-bottom-color: #cb1e5433;
}

.language-selector__option--selected {
  border-bottom-color: #cb1e54 !important;
}

.language-selector__option.disabled {
  opacity: .4;
}

.hidden {
  display: none;
}

.mini-event-details {
  flex-grow: 1;
}

.mini-event-details h2 {
  color: #cb1e54;
  font-size: 2rem;
  font-weight: bold;
}

.mini-event-details h2, .mini-event-details h3 {
  margin-top: .2rem;
  margin-bottom: .2rem;
}

#edit-event-details .modal-dialog {
  width: 800px;
  max-width: 100%;
}

#edit-event-details .modal-body {
  padding: .5rem 1rem;
}

#edit-event-details .modal-footer {
  justify-content: space-between;
}

.form-group label.edit__event__tab, .form-inline label.edit__event__tab, .edit__event__tab {
  color: #cb1e54;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-top: 1px solid #0000;
  border-bottom: 3px solid #0000;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: .75rem;
  font-weight: 600;
  text-decoration: none;
  transition: border-color .3s;
  display: flex;
}

.form-group label.edit__event__tab *, .form-inline label.edit__event__tab *, .edit__event__tab * {
  text-decoration: none;
}

.form-group label.edit__event__tab:hover, .form-inline label.edit__event__tab:hover, .edit__event__tab:hover {
  color: #cb1e54;
  text-decoration: none;
}

.form-group label.edit__event__tab .fas, .form-inline label.edit__event__tab .fas, .edit__event__tab .fas {
  margin-right: 2rem;
}

.form-group label.edit__event__tab *, .form-inline label.edit__event__tab *, .edit__event__tab * {
  cursor: pointer;
}

.form-group label.edit__event__tab:hover, .form-inline label.edit__event__tab:hover, .edit__event__tab:hover {
  border-bottom-color: #cb1e5433;
}

.form-group label.edit__event__tab--selected, .form-inline label.edit__event__tab--selected, .edit__event__tab--selected {
  border-bottom-color: #cb1e54 !important;
}

.email-preview-popup {
  z-index: 9999;
  background: #000000e6;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.email-preview-popup__content {
  width: 90%;
  height: 90%;
}

.invite-guests iframe.email-preview-iframe-mobile, .invite-schedule iframe.email-preview-iframe-mobile, iframe.email-preview-iframe-mobile, .email-preview-iframe-mobile {
  flex: 0 0 537px;
  margin-left: auto;
  margin-right: auto;
}

.plus-guest-selector {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.plus-guest-selector select {
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  color: #333;
  appearance: menulist;
  background: none;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  outline: none;
  height: 42px;
  min-height: 42px;
  font-family: OMEGA CT Regular, OMEGA CT, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.plus-guest-selector select.select--selected {
  color: #fff;
  background: #cb1e54;
}

.plus-guest-selector select.select--selected option {
  color: #333;
  background-color: #fff;
}

.plus-guest-selector select.custom-select:focus {
  box-shadow: none;
  border-color: #000c;
}

.plus-guest-selector button:first-child {
  margin-bottom: 4px;
}

.plus-guest-selector button[data-value="2"] {
  transform: translateX(-1px);
}

.plus-guest-selector select {
  transform: translateX(-2px);
}

.plus-guest-selector .additional_guests_input {
  text-align: center;
  border: 1px solid #e2e2e2;
}

.plus-guest-selector .input--selected {
  color: #fff;
  background: #cb1e54;
}

#plus-one-popup__title {
  align-items: center;
  display: flex;
}

.plus-one__vip-badge {
  margin-right: 12px;
  display: block;
}

.plus-one__vip-badge svg {
  width: 31px;
  height: 28px;
  display: block;
}

#edit-member-details .modal-dialog {
  width: 800px;
  max-width: 100%;
}

#edit-member-details .modal-body {
  padding: .5rem 1rem;
}

#edit-member-details .modal-footer {
  justify-content: space-between;
}

.ga-info-message {
  display: flex;
}

.ga-info-message .pill {
  flex-grow: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 2px;
}

@media screen {
  .swal2-styled.btn.btn, .btn.btn {
    will-change: transform;
    transform: translateZ(0);
  }
}

#ga__title {
  align-items: center;
  display: flex;
}

#edit-email-details .modal-dialog {
  width: 85vw;
  max-width: 100%;
}

#edit-email-details .modal-body {
  min-height: 77vh;
  display: flex;
}

#edit-email-details__form {
  width: 340px;
  overflow: auto;
}

button#edit-email__image {
  height: 100%;
}

.edit-email__iframe {
  border: 1px solid #e6e6e4;
  flex-grow: 1;
  margin: 0 1rem;
}

.modal-selector {
  align-items: center;
  margin-bottom: 0;
  margin-left: auto;
  display: flex;
}

.modal-selector__label, .form-group .modal-selector__label {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.modal-selector__radio, .modal-selector__label:not(:last-child) {
  margin-right: .5rem;
}

.image-browser {
  grid-template-columns: repeat(4, 1fr);
  gap: 7px;
  display: grid;
}

.image-browser__image {
  cursor: pointer;
  width: 100%;
  transition: box-shadow .2s;
  display: block;
}

.image-browser__image:hover {
  box-shadow: 0 0 3px 2px #ccc;
}

@media only screen and (width <= 1024px) {
  #edit-email-details__form {
    width: 100%;
  }

  #edit-email-details .modal-body {
    height: auto;
  }

  .edit-email__iframe {
    display: none;
  }
}

.member-status__notsent {
  color: #fff;
  background-color: #cb1e54;
}

.member-status__accepted {
  color: #fff;
  background-color: #06b7a5;
}

.member-status__sent {
  color: #fff;
  background-color: #7087bd;
}

.member-status__open {
  color: #fff;
  background-color: #4f6aab;
}

.member-status__resent {
  color: #fff;
  background-color: #7087bd;
}

.member-status__declined {
  background-color: var(--background);
  color: #999;
}

.member-status__bounce {
  color: #fff;
  background-color: #c70101;
}

.member-status__undelivered {
  color: #999;
  background-color: #c70101;
}

.schedule-status__notsent {
  color: #fff;
  background-color: #cb1e54;
}

.schedule-status__sent {
  color: #fff;
  background-color: #7087bd;
}

.schedule-status__open, .schedule-status__click, .schedule-status__resent {
  color: #fff;
  background-color: #06b7a5;
}

.schedule-status__bounce {
  color: #fff;
  background-color: #c70101;
}

.schedule-status__undelivered {
  color: #999;
  background-color: #c70101;
}

.event-status__draft {
  color: #fff;
  background-color: #cb1e54;
}

.event-status__live {
  color: #fff;
  background-color: #06b7a5;
}

.event-status__open {
  color: #fff;
  background-color: #7087bd;
}

.event-status__completed {
  background-color: var(--background);
  color: #999;
}

.guest-status__notyet {
  color: #fff;
  background-color: #999;
}

.guest-status__accepted {
  color: #fff;
  background-color: #06b7a5;
}

.guest-status__sent {
  color: #fff;
  background-color: #7087bd;
}

.guest-status__open {
  color: #fff;
  background-color: #4f6aab;
}

.guest-status__resent {
  color: #fff;
  background-color: #7087bd;
}

.guest-status__declined {
  background-color: var(--background);
  color: #999;
}

.guest-status__bounce {
  color: #fff;
  background-color: #c70101;
}

.guest-status__undelivered {
  color: #999;
  background-color: #c70101;
}

.guest-status__waitlist {
  color: #fff;
  background-color: #cb1e54;
}

.guest-status__rejected, .guest-status__notattended {
  color: #fff;
  background-color: #000;
}

.guest-status__arrived {
  color: #fff;
  background-color: #047c70;
}

.guest-status__notyetarrived {
  background-color: var(--background);
  color: #999;
}
/*# sourceMappingURL=index.6efa9ddd.css.map */
