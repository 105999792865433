table.datatable.datatable {
  border-bottom: none;
  max-width: 100%;

  thead th {
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: none;
    background-color: transparent;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  thead .sorting {
    background-image: none;
  }

  tbody {
    /* Hover styles */
    // tr {
    //   td {
    //     transition: background-color 200ms;
    //   }
    // }
    tr:hover {
      td:not(.dataTables_empty) {
        background-color: $title-color;
        border-color: transparent;
        color: white;
        cursor: pointer;
        * {
          cursor: pointer;
        }
      }
    }

    tr:nth-child(even) {
      background: var(--even-row-background);
    }
    tr:nth-child(odd) {
      background: var(--odd-row-background);
    }
    td {
      border-left: solid 1px var(--column-line-color);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0.7rem 1rem;
    }
    td:first-child {
      border: none;
    }
  }

  /* 'Highlighted' cell color */
  tr:not(:hover) {
    td.dt-highlight {
      color: $title-color;
    }
  }
}

.dataTables_info {
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.85rem;
  margin-left: 1rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding-top: 0;
}

// Datatable with selectable rows
table.selectable.dataTable {
  tbody {
    /* Hover styles */
    tr:hover {
      td:not(.dataTables_empty) {
        background-color: transparentize($title-color, 0.9);
        border-color: transparent;
        color: var(--base-color);
      }
    }

    tr.selected,
    tr.selected:hover {
      td:not(.dataTables_empty) {
        background-color: $title-color;
        border-color: transparent;
        color: white;
      }
    }
  }

  // Selectable rows - checkbox
  tbody td.select-checkbox:before,
  tbody th.select-checkbox:before {
    border: solid 1px var(--input-border-color);
  }
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom-color: transparent;
}

.dataTables_filter {
  position: relative;
  label:before {
    content: '\f002';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    background-color: white;
    border-right: solid 1px #e6e6e4;
    width: $button-height;
    height: calc(#{$button-height} - 2px);
    display: inline-block;
    margin: 0;
    line-height: 36px;
    font-size: 1rem;
    color: #cb1e54;
    position: absolute;
    left: 2px;
    top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    padding-left: calc(#{$button-height} + 1rem) !important;
    height: calc(#{$button-height} + 2px) !important;
  }
}

.dataTables_wrapper {
  .dataTables_scrollBody {
    background-color: #f7fafa;
  }

  .dataTables_filter input {
    margin-left: 0px;
  }

  .dataTables_info {
    padding: 0.7rem 0;
  }

  .dataTables_paginate .paginate_button.current,
  .dataTables_paginate .paginate_button.current:hover {
    color: white !important;
    background: none;
    background-color: $title-color;
    border: none;
  }

  .dataTables_paginate .paginate_button:hover {
    color: var(--base-color) !important;
    background: none;
    background-color: white;
    border-color: transparent;
  }

  .dataTables_paginate {
    text-transform: uppercase;
  }

  .select-info {
    line-height: 1.4rem;

    .select-item:first-child {
      background-color: $title-color;
      color: white;
      float: left;
      padding: 0px 1rem;
      margin-right: 1rem;
    }
  }

  th.select-checkbox {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    .fas {
      color: $title-color;
    }
  }
}

.dt-wrap {
  min-width: 400px;
  max-width: 400px;
  white-space: normal !important;
  
}
// th.select-checkbox,
// td.select-checkbox {
//   max-width: 42px !important;
//   width: 42px !important;
//   padding: 0 !important;
// }

/* lg - iPad landscape and smaller */
@media only screen and (max-width: 1024px) {
  table.datatable.datatable tbody td {
    padding: 0.5rem 0.5rem;
  }
}

