.plus-guest-selector {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  select {
    border-radius: 0;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 0;
    min-height: $button-height;
    height: $button-height;
    outline: none;
    text-align: center;
    letter-spacing: 1px;
    font-family: $title-font;
    font-weight: 700;
    font-size: 16px;
    color: $base-color;
    background: transparent;
    border: 1px solid $button-border-color;
    appearance: menulist;
    -webkit-appearance: menulist;
  }

  // If a value is selected...
  select.select--selected {
    color: #fff;
    background: $title-color;
    option {
      color: $base-color;
      background-color: white;
    }
  }

  // Override some bootstrap styling
  select.custom-select:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.8);
  }

  button:first-child {
    margin-bottom: 4px;
  }

  button[data-value='2'] {
    transform: translateX(-1px);
  }
  select {
    transform: translateX(-2px);
  }

  .additional_guests_input {
    border: 1px solid $button-border-color;
    text-align: center;
  }

  .input--selected {
    color: #fff;
    background: $title-color;
  }
}

#plus-one-popup__title {
  display: flex;
  align-items: center;
}

.plus-one__vip-badge {
  display: block;
  margin-right: 12px;
}

.plus-one__vip-badge svg {
  display: block;
  width: 31px;
  height: 28px;
}