.guest-list {
  width: 1200px;

  &__filter-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__top-bar {
    margin-bottom: 0;

    display: grid;
    grid-template: auto / 0.5fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 2rem;
    align-items: flex-start;

    .btn {
      margin-top: 1rem;
      margin-right: 1rem;
    }
    .btn:last-child {
      margin-right: 0;
    }
  }

  &__main {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    > .dataTables_wrapper {
      flex-grow: 1;
      width: 1px;
    }
  }

  &__datatable {
    flex-grow: 1;
  }

  &__left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 1px;
  }

  &__search-bar {
    display: flex;
    .dataTables_filter {
      /* width: 100%; */
      flex-grow: 1;
      width: 1px;
    }
  }

  &__status-selection {
    height: $button-height + 2px;
    margin-right: 1rem;
    min-width: 17rem;
    width: auto;
  }

  &__side-column {
    flex-shrink: 0;
    flex-basis: 350px;
    max-width: 35%;

    padding: 0 0 0 2rem;
    display: flex;
    flex-direction: column;

    .btn {
      margin-bottom: 0.8rem;
    }

    > h3:first-of-type {
      margin-top: 0.8rem;
    }

    .data-row__label {
      flex-basis: auto;
      flex-grow: 1;
      max-width: none;
    }
    .data-row__value {
      flex-basis: auto;
      flex-grow: 1;
      max-width: none;
      text-align: right;
      justify-content: flex-end;
    }

    .data-row__disabled {
      opacity: 0.4;
    }
   }
}

.guest-list__vip-badge {
  display: block;
  margin-right: 7px;
}

.guest-list__vip-badge svg {
  display: block;
  width: 22px;
  height: 20px;
}