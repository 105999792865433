.mini-event-details {
  flex-grow: 1;

  h2 {
    color: $title-color;
    font-size: 2rem;
    font-weight: bold;
  }
  h2,
  h3 {
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;
  }
}
