.pill {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 120px;
  height: 26px;

  padding: 0;

  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: $title-font;

  background-color: #eeeeee;
  color: #555555;

  //pill active 
&.pill-active {
  background-color: $blue;
  color: white;
  width: '100%';
  display: 'flex';
}


}
