.event-guest-list {
  &__top-bar {
    display: flex;
    flex-direction: row;
    h2 {
      color: $title-color;
      font-size: 2rem;
      font-weight: bold;
    }
    h2,
    h3 {
      margin-bottom: 0.2rem;
      margin-top: 0.2rem;
    }
  }

  &__main {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    margin-top: 0;

    > .dataTables_wrapper {
      width: 100%;
      height: 1px;
      flex-grow: 1;
    }
  }

  &__event-details {
    flex-grow: 1;
  }

  &__summary {
    min-width: 300px;

    .data-row__label {
      flex-basis: auto;
      flex-grow: 1;
      max-width: none;
    }
    .data-row__value {
      flex-basis: auto;
      flex-grow: 1;
      max-width: none;
      text-align: right;
      justify-content: flex-end;
    }

    display: grid;
    flex-grow: 1;
    white-space: nowrap;
    align-items: center;
    grid-template: 45px / 1fr 1fr;
    max-width: 440px;
  }
}

.dataTables_filter {
  width: 100%;
  label,
  input {
    width: 100%;
  }
}
.dataTables_wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.dataTables_scroll {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dataTables_scrollBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
}

.control_bar {
  display: flex
}

⁠.event-guest-list__summary {
  display: grid;
  flex-grow: 1;
  white-space: nowrap;
  align-items: center;
  grid-template: 45px / 1fr 1fr;
  max-width: 440px;
}

.event-guest-list__agency-list.form-control {

  margin-left: 7px;
  width: calc(100% - 14px);
}

.event-guest-list__market-responsible-list.form-control {

  margin-left: 7px;
  width: calc(100% - 14px);
}