.plus-badge {
  background-color: $title-color;
  color: white;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  position: absolute;
  right: -1rem;

  min-width: 2rem;
  height: 26px;
  padding: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: $title-font;

  &.linked {
    background-color: $blue;
  }
}

.schedule-sent {
  background-color: $title-color;
  color: white;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  position: absolute;
  right: -1rem;

  min-width: 2rem;
  height: 26px;
  padding: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: $title-font;
}





