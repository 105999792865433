#edit-event-details {
  .modal-dialog {
    max-width: 100%;
    width: 800px;
  }
  .modal-body {
    padding: 0.5rem 1rem;
  }
  .modal-footer {
    justify-content: space-between;
  }




}

.form-group label.edit__event__tab,
.form-inline label.edit__event__tab,
.edit__event__tab {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 1rem;
  border-top: solid 1px transparent;

  color: $title-color;

  font-family: $title-font;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  * {
    text-decoration: none;
  }
  &:hover {
    color: $title-color;
    text-decoration: none;
  }

  .fas {
    margin-right: 2rem;
  }

  cursor: pointer;
  * {
    cursor: pointer;
  }

  border-bottom: solid 3px transparent;
  transition: border-color 300ms;
  &:hover {
    border-bottom-color: fade-out($title-color, 0.8);
  }
  &--selected {
    border-bottom-color: $title-color !important;
  }
}