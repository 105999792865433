.member-list {
  &__top-bar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    .btn {
      margin-right: 1rem;
    }
    .separator {
      flex-grow: 1;
    }
  }

  &__filter-wrapper {
    margin: 0 1rem 0 0 !important;
  }

}

#member-list_filter {
  width: 300px;
  label {
    margin: 0;
  }
}

select.form-control.table__filter-select,
.table__filter-select {
  height: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.members-list-toggle {
  display: block;
  cursor: pointer;
  margin: 0 0.5rem .5rem;
}

@media (max-width: 1024px) {
  // Large devices or smaller (iPad landscape)
  .member-list {
    &__top-bar {
      .btn {
        label,
        span {
          display: none;
        }
        width: 49px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        i.fas {
          margin: auto;
        }
      }
      .separator {
        display: none;
      }
      #member-list_filter {
        flex-grow: 1;
        label,
        input {
          width: 100%;
        }
      }
    }
  }
}


