.ga-info-message {
  display: flex;

  .pill {
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 2px
  }
} 

@media screen {
  .swal2-styled.btn.btn, .btn.btn {
    transform: translateZ(0);
    will-change: transform;
  }
}

#ga__title {
  display: flex;
  align-items: center;
}