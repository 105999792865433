.modal-header {
  background-color: var(--section-background-1);
  align-items: center;
  border-bottom: none;
}

.modal-title {
  margin: 0;
  margin-left: 1rem;
}

.modal-content {
  border-radius: 0;
}

.modal-footer {
  //justify-content: space-between;
  justify-content: flex-start;
}
.modal-footer button {
  margin-right: 0.25rem !important
}
