#edit-email-details {
  .modal-dialog {
    max-width: 100%;
    width: 85vw;
  }
  .modal-body {
    display: flex;
    min-height: 77vh;
  }
  &__form {
    width: 340px;
    overflow: auto;
  }
}
button#edit-email__image {
  height: 100%;
}

.edit-email__iframe {
  flex-grow: 1;
  margin: 0 1rem;
  border: solid 1px $input-border-color;
}

.modal-selector {
  margin-bottom: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.modal-selector__label,
.form-group .modal-selector__label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-selector__radio {
  margin-right: 0.5rem;
}

.modal-selector__label:not(:last-child) {
  margin-right: 0.5rem;
}

.image-browser {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 7px;
}

.image-browser__image {
  display: block;
  width: 100%;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.image-browser__image:hover {
  box-shadow: 0 0 3px 2px #ccc;
}

/* lg - iPad landscape and smaller */
@media only screen and (max-width: 1024px) {
  #edit-email-details {
    &__form {
      width: 100%;
    }
    .modal-body {
      height: auto;
    }
  }

  .edit-email__iframe {
    display: none;
  }
}
