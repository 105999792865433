#edit-member-details {
  .modal-dialog {
    max-width: 100%;
    width: 800px;
  }

  .modal-body {
    padding: 0.5rem 1rem;
  }

  .modal-footer {
    justify-content: space-between;
  }
}
