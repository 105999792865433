.form-control.table__filter-select {
	margin: 0 1rem 0 0 !important;
}

input[type='text'],
input[type='search'],
input[type='password'],
select,
select.form-control,
textarea.form-control {
  border: solid 1px var(--input-border-color);
  padding: 0.5rem 1rem;
  border-radius: 0;
  outline: none;

  &:focus {
    outline: #cb1e54 solid 2px;
    border-color: transparent;
    box-shadow: none;
  }

  &.field-error {
    border-color: $title-color;
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}

// .form-group input[type='checkbox'] {
//   flex-grow: 0;
// }

// input[type='checkbox'].form-control {
//   width: calc(1.5em + .75rem + 2px);
// }

select,
select.form-control {
  padding-top: 0;
  padding-bottom: 0;
}

.input-group {
  .fas {
    color: $title-color;
  }

  .input-group-text {
    background: $input-background;
    min-height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type='text'],
  input[type='password'],
  select {
    min-height: 40px;
  }
}

.form-section {
  border: 1px solid #ccc;
  padding: 0.35rem 0.5rem;
  margin: 0 0 0.4rem 0;

  &__label {
    padding-left: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    text-align: left;
    align-items: flex-start;
    justify-content: flex-end;

    font-family: $title-font;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    /* Don't wrap - show ellipsis if text exceeds width */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.form-group,
.form-inline {
  background-color: var(--even-row-background);
  padding: 0.35rem 0.5rem;
  margin: 0 0 0.4rem 0;

  label {
    padding-left: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    text-align: left;
    align-items: flex-start;
    justify-content: flex-end;

    font-family: $title-font;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    /* Don't wrap - show ellipsis if text exceeds width */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  input,
  select {
    flex-grow: 1;
  }

  &.no-margin {
    margin: 0;
  }

  &.no-bottom-margin {
    margin-bottom: 0;
  }

  &.no-top-margin {
    margin-top: 0;
  }
}

.form-group {
  label {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
  }
  input,
  select {
    flex-grow: 1;
  }
}

.custom-file-label,
.input-group-text {
  border-radius: 0;
  border-color: var(--input-border-color);
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: $base-font;
  border: solid 1px var(--input-border-color);
  -webkit-text-fill-color: #333333;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-inline {
  .btn.btn {
    height: 32px;
    width: 32px;
    margin: 2px;
    padding: 0;

    border: 0;
    background: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;

    i.fas {
      margin: 0;
      padding: 0;
      transform: translate(1px, 0px);
    }
  }
}

.hidden {
  display: none;
} 

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-grow-0 {
  flex-grow: 0;
}


/* md - iPad portrait and smaller */
@media only screen and (max-width: 768px) {
  .form-inline {
    label {
      text-align: left;
      justify-content: flex-start;
      margin-bottom: 0.3rem;
    }
  }
}

