.login {
  background: white;
  display: flex;
  flex-grow: 1;
  height: 100vh;
  max-height: 100vh;

  &__right {
    flex-grow: 1;
    flex-basis: 50%;
    flex-shrink: 0;
    min-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }

  &__left {
    flex-grow: 1;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__logo {
    width: 220px;
    margin-bottom: 6rem;
    image-rendering: optimizeQuality;

    object-fit: contain;
  }

  &__inner {
    padding: 2.5rem 0rem;
    max-width: 100%;
    width: 400px;
    border-top: solid 2px $title-color;
    justify-content: center;
  }

  &__extras {
    display: flex;
    justify-content: space-between;
  }

  .input-group {
    margin-bottom: 2rem;
  }

  &__buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  &__error-container {
    color: $red;
    text-align: center;
    height: 2rem;
  }

  // Change input border color
  input,
  .input-group-text,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border-color: #818485 !important;
  }
}

.login__forgot-password {
  cursor: pointer;
  &:hover {
    color: $title-color;
  }
}

@media (max-width: 767px) {
  .login {
    &__left {
      display: none;
    }

    &__right {
      min-width: 0;
    }

    &__inner {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}