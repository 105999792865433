.add-guests.side-panel {
  //width: 1400px;
  width: 100%;
  position: fixed;

  &__table-buttons {
    margin-top: 1rem;
    margin-top: 0.5rem;
  }
  &__top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn {
      margin-right: 1rem;
    }
    .separator {
      flex-grow: 1;
    }
  }
  .dataTables_filter {
    margin-top: 0.5rem;
    display: flex;

    flex-wrap: wrap;
    align-items: initial;
    column-gap: 1rem;

    label {
      flex-grow: 1;
      width: auto;
      min-width: 280px;
    }
    > div {
      width: auto;
      min-width: 280px;

      select {
        width: 100% !important;
        min-height: 44px;
        margin: 0 !important;
      }
    }

  }
}
