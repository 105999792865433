.email-templates {
  &__row {
    background-color: var(--even-row-background);
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    > *:first-child {
      flex-grow: 1;
      font-family: $title-font;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .btn {
      margin-left: 1rem;
    }
  }
  &__row:nth-child(odd) {
    background-color: var(--odd-row-background);
  }
}
