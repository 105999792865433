.alert {
  border-radius: 0px;
}

.swal2-container {
  .swal2-styled.btn.btn {
    margin: 0.2rem 1rem;
    border: 1px solid #e2e2e2 !important;

    &:not(:disabled):hover:before {
      display: none;
    }
    &:not(:disabled):hover {
      background-color: $brand-color;
      color: white;
    }
  }
}
